var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('form', [_c('h5', [_vm._v(" Container details "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "8",
      "md": "8",
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.container_number.id,
      "value": _vm.data.container_number,
      "label": _vm.controls.container_number.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.size.id,
      "value": _vm.data.size,
      "label": _vm.controls.size.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.weight.id,
      "value": _vm.data.weight,
      "label": _vm.controls.weight.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created.id,
      "value": _vm.data.created,
      "label": _vm.controls.created.label,
      "readonly": true,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1)], 1), _c('b-col', [_c('b-card', {
    staticClass: "border-0 d-flex align-items-center"
  }, [_c('Progress', {
    attrs: {
      "transition-duration": 500,
      "radius": 70,
      "stroke-width": 15,
      "value": _vm.data.fullness_percent,
      "stroke-color": _vm.data.fullness_percent > 100 ? 'red' : '#AAFF00'
    }
  })], 1)], 1)], 1), _c('hr')], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.dataset, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.dataset, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.dataset, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.dataset, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Ccontainers'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Container submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Container submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.distillations.title
    }
  }, [_c('inline-switch', {
    attrs: {
      "id": _vm.controls.show_all_distillations.id,
      "value": _vm.data.show_all_distillations,
      "readonly": _vm.controls.show_all_distillations.readonly,
      "label": _vm.controls.show_all_distillations.label
    },
    on: {
      "changed": _vm.updateShowAllDistillationsSwitch
    }
  }), _c('dist-items-table', {
    ref: "dist-items-table",
    attrs: {
      "container-id": _vm.id
    },
    on: {
      "loaded": _vm.onDistillationItemsLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.alc_fin.title
    }
  }, [_c('inline-switch', {
    attrs: {
      "id": _vm.controls.show_all_alc_fin.id,
      "value": _vm.data.show_all_alc_fin,
      "readonly": _vm.controls.show_all_alc_fin.readonly,
      "label": _vm.controls.show_all_alc_fin.label
    },
    on: {
      "changed": _vm.updateShowAllAlcFinSwitch
    }
  }), _c('alcohol-finishing-table', {
    ref: "alcohol-finishing-table",
    attrs: {
      "container-id": _vm.id
    },
    on: {
      "loaded": _vm.onAlcFinItemsLoad
    }
  })], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }