<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Overview" active>
          <form>
            <h5>
              Container details
              <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
            </h5>

            <hr />

            <b-row>
              <b-col lg="8" md="8" sm="12">
                <b-row>
                  <b-col lg="6" md="6" sm="6">
                    <inline-input
                      :id="controls.container_number.id"
                      :value="data.container_number"
                      :label="controls.container_number.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="6" md="6" sm="6">
                    <inline-input
                      :id="controls.size.id"
                      :value="data.size"
                      :label="controls.size.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" md="6" sm="6">
                    <inline-input
                      :id="controls.weight.id"
                      :value="data.weight"
                      :label="controls.weight.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>

                  <b-col lg="6" md="6" sm="6">
                    <inline-input
                      :id="controls.created.id"
                      :value="data.created"
                      :label="controls.created.label"
                      :readonly="true"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-card class="border-0 d-flex align-items-center">
                  <Progress
                    :transition-duration="500"
                    :radius="70"
                    :stroke-width="15"
                    :value="data.fullness_percent"
                    :stroke-color="
                      data.fullness_percent > 100 ? 'red' : '#AAFF00'
                    "
                  />
                </b-card>
              </b-col>
            </b-row>
            <hr />
          </form>

          <hr />
          <form-submission-actions
            :mode="mode"
            :loading="{
              save: saveInProgress,
              saveAndView: saveAndViewInProgress
            }"
            :buttons-visibility="{
              previous: $customTable.getPrevKey(dataset, id),
              next: $customTable.getNextKey(dataset, id)
            }"
            @previous-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getPrevKey(dataset, id)
                }
              })
            "
            @next-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getNextKey(dataset, id)
                }
              })
            "
            @save="
              save('tabular').then(response =>
                response ? $router.push({ name: 'Ccontainers' }) : false
              )
            "
            @save-and-view="
              save('view').then(response =>
                response
                  ? $router.push({
                      name: 'Container submission',
                      params: { action: 'view', id: response }
                    })
                  : false
              )
            "
            @edit="
              $router.push({
                name: 'Container submission',
                params: { action: 'edit', id: id }
              })
            "
            @back="$router.push($store.getters['router/previousRoute'])"
          />
        </b-tab>
        <b-tab
          :title="controls.tabs.distillations.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <inline-switch
            :id="controls.show_all_distillations.id"
            :value="data.show_all_distillations"
            :readonly="controls.show_all_distillations.readonly"
            :label="controls.show_all_distillations.label"
            @changed="updateShowAllDistillationsSwitch"
          />

          <dist-items-table
            ref="dist-items-table"
            :container-id="id"
            @loaded="onDistillationItemsLoad"
          ></dist-items-table>
        </b-tab>
        <b-tab
          :title="controls.tabs.alc_fin.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <inline-switch
            :id="controls.show_all_alc_fin.id"
            :value="data.show_all_alc_fin"
            :readonly="controls.show_all_alc_fin.readonly"
            :label="controls.show_all_alc_fin.label"
            @changed="updateShowAllAlcFinSwitch"
          />

          <alcohol-finishing-table
            ref="alcohol-finishing-table"
            :container-id="id"
            @loaded="onAlcFinItemsLoad"
          ></alcohol-finishing-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import Progress from "easy-circular-progress";
import StorageContainerDistItemsTable from "@/views/Production/StorageContainers/StorageContainerDistItemsTable.vue";
import AlcoholFinishingTable from "@/views/Production/AlcoholFinishing/AlcoholFinishingTable.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "ContainerSubmissionForm",
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  components: {
    "dist-items-table": StorageContainerDistItemsTable,
    AlcoholFinishingTable,
    Progress
  },
  data: function() {
    return {
      dataset: {
        name: "storage-containers"
      },
      baseUrl: "storage-container",
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        tabs: {
          distillations: {
            title: "Distillations"
          },
          alc_fin: {
            title: "Alcohol finishings"
          }
        },
        show_all_distillations: {
          id: "switch:show_all_distillations",
          label: "Show all distillations",
          changed: false,
          readonly: false
        },
        show_all_alc_fin: {
          id: "switch:show_all_alc_fin",
          label: "Show all alcohol finishings",
          changed: false,
          readonly: false
        },
        container_number: {
          id: "input:container_number",
          label: "Container Number",
          changed: false,
          readonly: false
        },
        size: {
          id: "input:size",
          label: "Size",
          changed: false,
          readonly: false
        },
        weight: {
          id: "input:weight",
          label: "Weight",
          changed: false,
          readonly: false
        },
        created: {
          id: "input:created",
          label: "Created",
          changed: false,
          readonly: false
        }
      },
      data: {
        id: "",
        container_number: "",
        size: "",
        weight: "",
        created: "",
        fullness_percent: 0,
        show_all_distillations: false,
        show_all_alc_fin: false
      },
      datasetKeys: []
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    initialize: async function() {
      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
      }
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.data.id = this.id;

      this.fetchDropdowns();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        await this.fetchData(this.id);
      }
    },

    fetchDropdowns() {},
    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");

        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let url = this.baseUrl,
        method = "put";

      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        url = `${this.baseUrl}/${this.data.id}`;
      }

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    updateShowAllDistillationsSwitch(field, value) {
      this.data.show_all_distillations = value;

      this.$refs["dist-items-table"].getData({
        active_items: !this.data.show_all_distillations
      });
    },
    updateShowAllAlcFinSwitch(field, value) {
      this.data.show_all_alc_fin = value;

      this.$refs["alcohol-finishing-table"].getData({
        container_id: this.id,
        active_items: !this.data.show_all_alc_fin
      });
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },
    updateField(field, value, mode) {
      this.$form.updateField(this.baseUrl, this, field, value, mode);
    },
    updateSelect(id, value) {
      this.$form.updateField(this.baseUrl, this, id, value, this.mode);
    },

    async fetchData(id) {
      let self = this;

      this.isLoading = true;
      return this.$api
        .get(`${this.baseUrl}/${id}`)
        .then(response => {
          this.isLoading = false;

          if (self.$_.isEmpty(response)) return;

          self.data.id = self.id;

          self.data.container_number = response["Container Number"];
          self.data.size = response["Size"];
          self.data.weight = response["Weight"];
          self.data.created = response["Created"];
          self.data.fullness_percent = +response["Fullness %"];
        })
        .catch(error => {
          this.isLoading = false;

          console.log(error);
        });
    },
    onAlcFinItemsLoad(count) {
      if (count > 0)
        this.controls.tabs.alc_fin.title = `Alcohol finishings (${count})`;
    },
    onDistillationItemsLoad(count) {
      if (count > 0)
        this.controls.tabs.distillations.title = `Distillations (${count})`;
    }
  },
  watch: {}
};
</script>

<style></style>
