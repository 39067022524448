<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(dataset)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row)"
            title="Delete item"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";

export default {
  props: {
    containerId: {
      type: [String, Number],
      default: null
    }
  },
  name: "StorageContainerItemsTable",
  components: {
    TableCustom
  },
  data: function() {
    return {
      dataset: {
        name: "distillation-storages"
      },
      mode: null,
      rawData: {},
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          showEmpty: true,
          filterable: [],
          columns: [
            "ID",
            "Base Product",
            "Distillation ID",
            "Distillate",
            "Amount",
            "Amount Remaining",
            "Created By",
            "Created"
          ],

          editableColumns: [],
          maskedColumns: [],
          dropdownColumns: [],
          perPage: 50,
          showCustomActions: true,
          showChildRows: true,
          selectableRows: this.selectableRows
        },
        childRow: {
          showDescription: false
        }
      }
    };
  },
  computed: {},
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.mode = this.$constants.FORM_MODE.VIEW;

      if (!this.$form.isCreateMode(this) && this.containerId) {
        this.getData();
      }
    },
    async getData(payload = { active_items: true }) {
      let self = this;

      this.dataTable.isLoading = true;

      let response = [];

      try {
        response = await this.$api.post(
          `storage-container/${this.containerId}/distillations`,
          payload
        );

        self.dataTable.isLoading = false;

        self.dataTable.dataSet = response;

        if (response.length === 0) return;

        self.$emit("loaded", self.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);
        self.dataTable.isLoading = false;
        self.$form.msgBoxOk("Error occured");
      }
    }
  },
  watch: {}
};
</script>

<style scoped></style>
